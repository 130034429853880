<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      <el-row style="padding-bottom: 10px">
        <el-col :span="24" style="text-align: left;line-height: 40px">
          <el-input ref="assetSn" v-model.trim="assetSn" placeholder="请输入或扫资产码" style="width: 350px" @keyup.enter.native="deleteItem"></el-input>
          <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="deleteItem">删除</el-button>
          <MessageComponent ref="addMessage"/>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>

import MessageComponent from "@/components/MessageComponent";

export default {
  name: "DelUseLog",
  components: {MessageComponent},
  data() {
    return {
      assetSn: '',
      loading: false,
    }
  },
  methods: {
    deleteItem() {
      this.$refs.addMessage.clear();

      if (!this.assetSn) {
        return this.$refs.addMessage.showError('请输入或扫资产码');
      }

      this.$refs.assetSn.select();

      this.loading = true;
      this.$axios.post('recyclableBox/deleteUseLog', this.assetSn).then(res => {
        this.loading = false;
        if (res.data.code !== 0) {
          return this.$refs.addMessage.showError(res.data.message);
        }
        this.assetSn = ''
        this.$refs.addMessage.showSuccess(res.data.message);
      }, error => {
        this.loading = false;
        return this.$refs.addMessage.showError(error.message);
      });
    }
  }
}
</script>

<style scoped>

</style>
